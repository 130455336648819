import { Box, Container, Typography } from '@mui/material';
import React from 'react';

const PrivacyNotice = () => {
	return (
		<Container component={Box} py={6}>
			<Typography variant='h1' gutterBottom>
				Privacy Notice
			</Typography>
			<Typography variant='h5'>Last Updated: 12 April 2022</Typography>
			<br />
			<p>
				This Privacy Notice describes how we collect and use your personal information in relation to DeepAR websites,
				applications, products, services, events, and experiences that reference this Privacy Notice (together, “DeepAR
				Offerings”).
			</p>
			<p>
				This Privacy Notice does not apply to the “content” processed, stored, or hosted by our customers using DeepAR
				Offerings in connection with a DeepAR account. This Privacy Notice also does not apply to any products,
				services, websites, or content that are offered by third parties or have their own privacy notice.
			</p>
			<br />

			<Typography variant='h3'>Personal Information We Collect</Typography>
			<p>We collect your personal information in the course of providing DeepAR Offerings to you.</p>
			<p>Here are the types of information we gather:</p>
			<ul>
				<li>Information You Give Us:We collect any information you provide in relation to DeepAR Offerings.</li>
				<li>
					Automatic Information:We automatically collect certain types of information when you interact with DeepAR
					Offerings.
				</li>
				<li>
					Information from Other Sources:We might collect information about you from other sources, including service
					providers, partners, and publicly available sources.
				</li>
			</ul>
			<br />

			<Typography variant='h3'>How We Use Personal Information</Typography>
			<p>
				We use your personal information to operate, provide, and improve DeepAR Offerings. Our purposes for using
				personal information include:
			</p>
			<ul>
				<li>
					Provide DeepAR Offerings:We use your personal information to provide and deliver DeepAR Offerings and process
					transactions related to DeepAR Offerings, including registrations, subscriptions, purchases, and payments.
				</li>
				<li>
					Measure, Support, and Improve DeepAR Offerings:We use your personal information to measure use of, analyze
					performance of, fix errors in, provide support for, improve, and develop DEEPAR Offerings.
				</li>
				<li>
					Recommendations and Personalization:We use your personal information to recommend DeepAR Offerings that might
					be of interest to you, identify your preferences, and personalize your experience with DeepAR Offerings.
				</li>
				<li>
					Comply with Legal Obligations:In certain cases, we have a legal obligation to collect, use, or retain your
					personal information.
				</li>
				<li>
					Communicate with You:We use your personal information to communicate with you in relation to DeepAR Offerings
					via different channels (e.g., by phone, email, chat) and to respond to your requests.
				</li>
				<li>
					Marketing:We use your personal information to market and promote DeepAR Offerings. We might display
					interest-based ads for DeepAR Offerings.
				</li>
				<li>
					Fraud and Abuse Prevention and Credit Risks:We use your personal information to prevent and detect fraud and
					abuse in order to protect the security of our customers, DeepAR, and others. We may also use scoring methods
					to assess andmanage credit risks.
				</li>
				<li>
					Purposes for Which We Seek Your Consent:We may also ask for your consent to use your personal information for
					a specific purpose that we communicate to you.
				</li>
			</ul>
			<br />

			<Typography variant='h3'>Cookies</Typography>
			<p>
				To enable our systems to recognize your browser or device and to provide DeepAR Offerings to you, we use
				cookies.
			</p>
			<br />

			<Typography variant='h3'>How We Share Personal Information</Typography>
			<p>
				Information about our customers is an important part of our business and we are not in the business of selling
				our customers’ personal information to others. We share personal information only as described below and with I
				Love IceCream Ltd. and the subsidiaries that I Love IceCream Ltd. controls that are either subject to this
				Privacy Notice or follow practices at least as protective as those described in this Privacy Notice.
			</p>
			<ul>
				<li>
					Transactions Involving Third Parties: We make available to you services, software, and content provided by
					third parties for use on or through DeepAR Offerings. You can tell when a third party is involved in your
					transactions, and we share information related to those transactions with that third party.{' '}
				</li>
				<li>
					Third-Party Service Providers: We employ other companies and individuals to perform functions on our behalf.
					Examples include: sending communications, processing payments, assessing credit and compliance risks,
					analyzing data, providing marketing and sales assistance (including advertising and event management),
					conducting customer relationship management, and providing training. These third-party service providers have
					access to personal information needed to perform their functions, but may not use it for other purposes.
					Further, they must process that information in accordance with this Privacy Notice and as permitted by
					applicable data protection law.
				</li>
				<li>
					Business Transfers: As we continue to develop our business, we might sell or buy businesses or services. In
					such transactions, personal information generally is one of the transferred business assets but remains
					subject to the promises made in any pre-existing Privacy Notice (unless, of course, the individual consents
					otherwise). Also, in the unlikely event that DeepAR or substantially all of its assets are acquired, your
					information will of course be one of the transferred assets.
				</li>
				<li>
					Protection of Us and Others: We release account and other personal information when we believe release is
					appropriate to comply with the law, enforce or apply our terms and other agreements, or protect the rights,
					property, or security of DeepAR, our customers, or others. This includes exchanging information with other
					companies and organizations for fraud prevention and detection and credit risk reduction.
				</li>
				<li>
					At Your Option: Other than as set out above, you will receive notice when personal information about you might
					be shared with third parties, and you will have an opportunity to choose not to share the information.
				</li>
			</ul>
			<br />

			<Typography variant='h3'>Location of Personal Information</Typography>
			<p>
				I Love IceCream Ltd. is located in the United Kingdom, and our affiliated companies are located throughout the
				world. Depending on the scope of your interactions with DeepAR Offerings, your personal information may be
				stored in or accessed from multiple countries, including the United Kingdom. Whenever we transfer personal
				information to other jurisdictions, we will ensure that the information is transferred in accordance with this
				Privacy Notice and as permitted by applicable data protection.
			</p>
			<br />

			<Typography variant='h3'>How We Secure Information</Typography>
			<p>At DeepAR, security is our highest priority. We design our systems with your security and privacy in mind.</p>
			<ul>
				<li>We maintain a wide variety of compliance programs that validate our security controls.</li>
				<li>
					We protect the security of your information during transmission to or from DeepAR websites, applications,
					products, or services by using encryption protocols and software.
				</li>
				<li>We follow the Payment Card Industry Data Security Standard (PCI DSS) when handling credit card data.</li>
				<li>
					We maintain physical, electronic, and procedural safeguards in connection with the collection, storage, and
					disclosure of personal information. Our security procedures mean that we may request proof of identity before
					we disclose personal information to you.
				</li>
			</ul>
			<br />

			<Typography variant='h3'>Internet Advertising and Third Parties</Typography>
			<p>
				DeepAR Offerings may include third-party advertising and links to other websites and applications. Third party
				advertising partners may collect information about you when you interact with their content, advertising, or
				services.
			</p>
			<br />

			<Typography variant='h3'>Access and Choice</Typography>
			<p>
				You can view, update, and delete certain information about your account and your interactions with DeepAR
				Offerings. If you cannot access or update your information yourself, you can always contact us for assistance.
			</p>
			<p>
				You have choices about the collection and use of your personal information. Many DeepAR Offerings include
				settings that provide you with options as to how your information is being used. You can choose not to provide
				certain information, but then you might not be able to take advantage of certain DeepAR Offerings.
			</p>
			<ul>
				<li>
					Account Information:If you want to add, update, or delete information related to your account, please go to
					the DeepAR Account Section When you update or delete any information, we usually keep a copy of the prior
					version for our records.
				</li>
				<li>
					Communications:If you do not want to receive promotional messages from us, please unsubscribe or adjust your
					communication preferences. If you do not want to receive in-app notifications from us, please adjust your
					notification settings in the app or your device.
				</li>
				<li>
					Browser and Devices:The Help feature on most browsers and devices will tell you how to prevent your browser or
					device from accepting new cookies, how to have the browser notify you when you receive a new cookie, or how to
					disable cookies altogether.
				</li>
			</ul>
			<br />

			<Typography variant='h3'>Retention of Personal Information</Typography>
			<p>
				We keep your personal information to enable your continued use of DeepAR Offerings, for as long as it is
				required in order to fulfil the relevant purposes described in this Privacy Notice, as may be required by law
				(including for tax and accounting purposes), or as otherwise communicated to you. How long we retain specific
				personal information varies depending on the purpose for its use, and we will delete your personal information in
				accordance with applicable law.
			</p>
			<br />

			<Typography variant='h3'>Contacts, Notices, and Revisions</Typography>
			<p>
				If you have any concern about privacy at DeepAR or want to contact one of our data controllers, please contact
				us with a thorough description, and we will try to resolve it.
			</p>
			<p>
				If you interact with DeepAR Offerings on behalf of or through your organization, then your personal information
				may also be subject to your organization’s privacy practices, and you should direct privacy inquiries to your
				organization.
			</p>
			<p>
				Our business changes constantly, and our Privacy Notice may also change. You should check our website frequently
				to see recent changes. You can see the date on which the latest version of this Privacy Notice was posted.
				Unless stated otherwise, our current Privacy Notice applies to all personal information we have about you and
				your account. We stand behind the promises we make, however, and will never materially change our policies and
				practices to make them less protective of personal information collected in the past without informing affected
				customers and giving them a choice.
			</p>
			<br />

			<Typography variant='h3'>Additional Information for Certain Jurisdictions</Typography>
			<p>
				We provide additional information about the privacy, collection, and use of personal information of prospective
				and current customers of DeepAR Offerings located in certain jurisdictions.
			</p>
			<br />

			<Typography variant='h3'>Examples of Information Collected</Typography>

			<Typography variant='h5'>Information You Give Us</Typography>
			<p>You provide information to us when you:</p>
			<ul>
				<li>search for, subscribe to, or purchase DeepAR Offerings;</li>
				<li>
					create or administer your DeepAR account (and you might have more than one account if you have used more than
					one email address when using DeepAR Offerings);
				</li>
				<li>
					configure your settings for, provide data access permissions for, or otherwise interact with DeepAR Offerings;
				</li>
				<li>register for or attend an DeepAR event;</li>
				<li>communicate with us by phone, email, or otherwise;</li>
				<li>complete a questionnaire, a support ticket, or other information request forms;</li>
				<li>post on DeepAR websites or participate in community features; and</li>
				<li>employ notification services.</li>
			</ul>
			<p>Depending on your use of DeepAR Offerings, you might supply us with such information as:</p>
			<ul>
				<li>your name, email address, physical address, phone number, and other similar contact information;</li>
				<li>payment information, including credit card and bank account information;</li>
				<li>information about your location;</li>
				<li>
					information about your organization and your contacts, such as colleagues or people within your organization;
				</li>
				<li>usernames, aliases, roles, and other authentication and security credential information;</li>
				<li>
					content of feedback, testimonials, inquiries, support tickets, and any phone conversations, chat sessions and
					emails with or to us;
				</li>
				<li>
					your image (still, video, and in some cases 3-D), voice, and other identifiers that are personal to you when
					you attend an DeepAR event or use certain DeepAR Offerings;
				</li>
				<li>information regarding identity, including government-issued identification information;</li>
				<li>corporate and financial information; and</li>
				<li>VAT numbers and other tax identifiers.</li>
			</ul>

			<Typography variant='h5'>Automatic Information</Typography>
			<p>We collect information automatically when you:</p>
			<ul>
				<li>
					visit, interact with, or use DeepAR Offerings (including when you use your computer or other device to
					interact with DeepAR Offerings);
				</li>
				<li>download content from us;</li>
				<li>open emails or click on links in emails from us; and</li>
				<li>
					interact or communicate with us (such as when you attend an DeepAR event or when you request customer
					support).
				</li>
			</ul>
			<p>Examples of the information we automatically collect include:</p>
			<ul>
				<li>
					network and connection information, such as the Internet protocol (IP) address used to connect your computer or
					other device to the Internet and information about your Internet service provider;
				</li>
				<li>
					computer and device information, such as device, application, or browser type and version, browser plug-in type
					and version, operating system, or time zone setting;
				</li>
				<li>the location of your device or computer;</li>
				<li>authentication and security credential information;</li>
				<li>
					content interaction information, such as content downloads, streams, and playback details, including duration
					and number of simultaneous streams and downloads;
				</li>
				<li>
					DeepAR Offerings metrics, such as offering usage, occurrences of technical errors, diagnostic reports, your
					settings preferences, backup information, API calls, and other logs;
				</li>
				<li>
					the full Uniform Resource Locators (URL) clickstream to, through, and from ourwebsite (including date and
					time) and DEEPAR Offerings, content you viewed or searched for, page response times, download errors, and page
					interaction information (such as scrolling, clicks, and mouse-overs);
				</li>
				<li>email addresses and phone numbers used to contact us; and</li>
				<li>identifiers and information contained in cookies.</li>
			</ul>

			<Typography variant='h5'>Information from Other Sources</Typography>
			<p>Examples of information we receive from other sources include:</p>
			<ul>
				<li>
					marketing, sales generation, and recruitment information, including your name, email address, physical
					address, phone number, and other similar contact information;
				</li>
				<li>
					subscription, purchase, support, or other information about your interactions with products and services
					offered by us, our affiliates (such as DeepAR training courses), or third parties in relation to DEEPAR
					Offerings;
				</li>
				<li>search results and links, including paid listings (such as Sponsored Links); and</li>
				<li>credit history information from credit bureaus.</li>
			</ul>

			<Typography variant='h5'>Information You Can Access</Typography>
			<p>Examples of information you can access through DeepAR Offerings include:</p>
			<ul>
				<li>your name, email address, physical address, phone number, and other similar contact information;</li>
				<li>usernames, aliases, roles, and other authentication and security credential information;</li>
				<li>your subscription, purchase, usage, billing, and payment history;</li>
				<li>payment settings, such as payment instrument information and billing preferences;</li>
				<li>tax information;</li>
				<li>email communication and notification settings.</li>
			</ul>
			<br />
		</Container>
	);
};

export default PrivacyNotice;
